import { useState } from 'react';
import { notification, Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import ApiService from '../../../services/ApiService';
import SessionService from '../../../services/SessionService';
import { PageContainer, StyledForm } from './styles';

export const Login = () => {
  const [api, contextHolder] = notification.useNotification();
  const [isLoggingIn, setLoggingIn] = useState(false);

  const onLogin = async values => {
    setLoggingIn(true);
    try {
      const data = await ApiService.login(values);
      if (!data.success) {
        api.open({
          message: 'Sorry...',
          description: 'It seemed you entered the wrong username or password.',
        });
      } else {
        SessionService.setUserId(data.result.userId);
        SessionService.setUsername(data.result.userName);
        SessionService.setAccessToken(data.result.token);
        window.location.reload();
      }
    } catch (e) {
      //
    }
    setLoggingIn(false);
  };

  return (
    <>
      {contextHolder}
      <PageContainer>
        <h2 className="logo-text">The Akwaaba Foundation Presents</h2>
        <img className="logo-img" src="/images/logo.png" alt="Logo" />
        <p className="description">
          Your goal is to create a sustainable well-balanced country by meeting social, economic, and environmental
          objectives.
        </p>
        <StyledForm initialValues={{}} onFinish={onLogin} autoComplete="off">
          <h2 className="form-title">
            Log into Simpolicon<span style={{ fontSize: 16 }}>©2025</span>
          </h2>

          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Username is required!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Password is required!',
              },
            ]}
          >
            <Input.Password prefix={<LockOutlined />} type="password" placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoggingIn}>
              Log In
            </Button>
          </Form.Item>
        </StyledForm>
      </PageContainer>
    </>
  );
};
