import { Card, Modal } from 'antd';

export const QuickStartModal = ({ open, onClose }) => (
  <Modal width="80%" title="Quick Start" footer={null} open={open} onCancel={onClose}>
    <Card className="mt-16" size="small">
      1. Click the Production Button on the Dashboard.
      <br />
      <br />
      2. Choose a Category from the “Category” column: this time, choose Agricultural Products.
      <br />
      <br />
      3. Choose a Product from the “Product” column that appears: this time choose Lumber.
      <br />
      <br />
      4. Look at the Lumber page. Across the top left, you will see your country’s current amount of lumber and its GDP
      value per unit, and at the top right you’ll see how many units and pollution this production will make. Note how
      lumber is made and why lumber is useful.
      <br />
      <br />
      5. Choose a Process to actually make the lumber: this time choose the Forest Land and Unskilled Labor icons as
      your inputs. The Factor box is green indicating you have enough of both inputs to make the product.
      <br />
      <br />
      6. Enter a number for each input so that their multiplied value meets or exceeds the Factor value: 10 times 1, 1
      times 10, 5 times 2, or 2 times 5. The Input box will turn green indicating you are successfully making that
      product.
      <br />
      <br />
      7. Scroll to the bottom of the page and enter 1 where it asks “Repeat Procedure?”.
      <br />
      <br />
      8. Click the Produce Button and then the Confirm Button.
      <br />
      <br />
      9. Scroll to the top of the page and notice you have gained 100 units of Lumber.
      <br />
      <br />
      10. Repeat steps above for a different Category-Product-Process.
      <br />
      <br />
      <b>Explore!</b>
    </Card>
  </Modal>
);
