import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const NationalWealthTooltip = ({ title = 'National Wealth', showIcon = false }) => {
  const tooltip = (
    <>
      National Wealth is the <b>total value</b> of all Simpolicon outputs existing in your country at the present time.
      'Value' is based on the estimated relative difficulty of production.
    </>
  );

  return (
    <Tooltip title={tooltip}>
      {title}
      {showIcon && (
        <>
          &nbsp;
          <QuestionCircleOutlined />
        </>
      )}
    </Tooltip>
  );
};
