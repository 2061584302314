import { useEffect, useState } from 'react';
import { notification, Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { DOB_YEAR_LIST, GENDER_LIST, LANGUAGE_LIST } from '../../../data';
import ApiService from '../../../services/ApiService';
import DataService from '../../../services/DataService';

export const EditProfileModal = ({ open, onClose, onSave }) => {
  // const [account, setAccount] = useState(null);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const countryList = DataService.getItem('countryList');

  const fetchAccount = async () => {
    const data = await ApiService.retrieveAccountView();
    form.setFieldsValue(data.result);
    // setAccount(data.result);
  };

  const onFinish = async values => {
    setSubmitting(true);
    try {
      const payload = {
        ...values,
        // userId: account.userId,
        password: '',
      };
      await ApiService.updateAccount(payload);
      onSave(payload);
    } catch (e) {
      api.open({
        message: 'Sorry...',
        description: 'Something went wrong...',
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    fetchAccount();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      title="Edit Profile"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="save" type="primary" loading={isSubmitting} onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {contextHolder}
      <Form layout="vertical" form={form} onFinish={onFinish} requiredMark={false} autoComplete="off">
        <Form.Item
          label="Username"
          name="userName"
          rules={[
            {
              required: true,
              message: 'Username is required!',
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: 'First name is required!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: 'Last name is required!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: 'Email is required!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="genderId"
          rules={[
            {
              required: true,
              message: 'Gender is required!',
            },
          ]}
        >
          <Select showSearch placeholder="Select gender" options={GENDER_LIST} />
        </Form.Item>
        <Form.Item
          label="Primary Language"
          name="primaryLanguageId"
          rules={[
            {
              required: true,
              message: 'Primary Language is required!',
            },
          ]}
        >
          <Select showSearch placeholder="Select primary language" options={LANGUAGE_LIST} />
        </Form.Item>
        <Form.Item
          label="Country"
          name="countryId"
          rules={[
            {
              required: true,
              message: 'Country is required!',
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select country"
            optionFilterProp="countryName"
            fieldNames={{ label: 'countryName', value: 'countryId' }}
            options={countryList}
          />
        </Form.Item>
        <Form.Item
          label="Year of Birth"
          name="yearOfBirth"
          rules={[
            {
              required: true,
              message: 'Year of Birth is required!',
            },
          ]}
        >
          <Select showSearch placeholder="Select year of birth" options={DOB_YEAR_LIST} />
        </Form.Item>
        <Form.Item className="d-none">
          <Button type="primary" htmlType="submit"></Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
