import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import DataService from '../../../../services/DataService';
import ApiService from '../../../../services/ApiService';
import { EVENT_DETAILS_MAP, SIMULATION_COUNTRY_STATES } from '../../../../data';
import { MedicalHelpModal } from './MedicalHelpModal';

export const Welcome = () => {
  const navigate = useNavigate();
  const countryList = DataService.getItem('countryList');
  const eventGroupList = DataService.getItem('eventGroupList');
  const resourceList = DataService.getItem('resourceList');
  const { simulation, simulationCountry, fetchSimulationCountry } = useSimulationContext();
  const [step, setStep] = useState(1);
  const [events, setEvents] = useState([]);
  const [eventIndex, setEventIndex] = useState(0);
  const [isMedicalHelpModalOpen, setMedicalHelpModalOpen] = useState(false);
  const [isMedicalHelpDone, setMedicalHelpDone] = useState(false);

  const isYearStarted = useMemo(() => {
    if (simulationCountry?.simulationCountryState === SIMULATION_COUNTRY_STATES.NEW) {
      if (simulation?.simulationYearArray?.some(y => y?.GoalWeightingType === 2)) {
        return true;
      }
      return false;
    }
    return true;
  }, [simulation, simulationCountry]);

  useEffect(() => {
    if (
      simulationCountry &&
      ![SIMULATION_COUNTRY_STATES.NEW, SIMULATION_COUNTRY_STATES.NEW_YEAR].includes(
        simulationCountry.simulationCountryState,
      )
    ) {
      navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/national-briefing`);
      return;
    }
    if (simulationCountry && simulationCountry.simulationCountryState !== SIMULATION_COUNTRY_STATES.NEW && step === 1) {
      setStep(2);
    }
    // eslint-disable-next-line
  }, [simulationCountry]);

  useEffect(() => {
    if (isMedicalHelpDone) {
      handleMedicalHelpDone();
    }
    // eslint-disable-next-line
  }, [isMedicalHelpDone]);

  if (!simulationCountry) {
    return;
  }

  const title = `Welcome to ${simulationCountry.countryName}!`;
  const country = countryList.find(c => c.countryId === simulationCountry.countryId);
  const flagUrl = `/images/flags/${country.countryCode}.png`;
  const flag = <img src={flagUrl} width={40} alt={simulationCountry.countryName} />;

  const handleStartCountry = async () => {
    if (step === 1) {
      await ApiService.advanceSimulationCountry(simulationCountry.simulationCountryId, {
        carbohydrateObjectListString: '',
        proteinObjectListString: '',
        waterObjectListString: '',
        specialObjectListString: '',
      });
      setStep(2);
    } else if (step === 2) {
      await ApiService.advanceSimulationCountry(simulationCountry.simulationCountryId, {
        carbohydrateObjectListString: '',
        proteinObjectListString: '',
        waterObjectListString: '',
        specialObjectListString: '',
      });
      let { resultList } = await ApiService.retrieveSimulationCountryResourceTransactionListFiltered(
        simulationCountry.simulationCountryId,
        simulationCountry.simulationCountryYear,
        21,
      );
      resultList = resultList.filter(r =>
        ['Fortuitous Event', 'Disastrous Event', 'Medical Disaster Event', 'Natural Disaster Event'].includes(
          r.description,
        ),
      );
      resultList.sort((a, b) => (a.description > b.description ? -1 : 1));
      setEvents(resultList);
      setStep(3);
    } else if (step === 3) {
      if (events.length > 0 && eventIndex < events.length) {
        if (events[eventIndex].description === 'Medical Disaster Event' && !isMedicalHelpDone) {
          setMedicalHelpModalOpen(true);
          return;
        }

        setEventIndex(eventIndex + 1);
        return;
      }
      await fetchSimulationCountry();
      navigate(`/portal/simulations/${simulationCountry.simulationCountryId}/national-briefing`);
    }
  };

  const handleSaveMedicalBox = async uses => {
    await ApiService.undoMedicalEvent(simulationCountry.simulationCountryId, {
      clinicUses: uses[0],
      pharmacyUses: uses[1],
      doctorUses: uses[2],
      hospitalUses: uses[3],
    });
    setMedicalHelpModalOpen(false);
    setMedicalHelpDone(true);
  };

  const handleMedicalHelpDone = async () => {
    const { resultList } = await ApiService.retrieveSimulationCountryResourceTransactionListFiltered(
      simulationCountry.simulationCountryId,
      simulationCountry.simulationCountryYear,
      21,
    );
    const currentEvent = resultList.find(
      e => e.description === 'Medical Disaster Event' && e.submittedOn === events[eventIndex].submittedOn,
    );
    const savedCount = currentEvent.transactionQuantity - events[eventIndex].transactionQuantity;
    const result = await Swal.fire({
      title: 'Save People',
      text: `Number of people who have been saved: ${savedCount}`,
    });
    if (result.isConfirmed || result.isDismissed) {
      await handleStartCountry();
      setMedicalHelpDone(false);
    }
  };

  const renderEvents = () => {
    if (events.length === 0) {
      return (
        <Card title={title} extra={flag}>
          <div className="d-flex flex-column align-center">
            <h1>NO MAJOR EVENTS HAVE OCCURRED</h1>
            <img style={{ margin: '48px 0' }} src="/images/pages/simulation/welcome/sunshine.png" alt="Event" />
            <p>Please Proceed to the National Briefing</p>
            <Button type="primary" onClick={handleStartCountry}>
              Go To National Briefing
            </Button>
          </div>
        </Card>
      );
    } else {
      const event = events[eventIndex];
      if (!event) {
        return (
          <Card title={title} extra={flag}>
            <div className="d-flex flex-column align-center">
              <h1>No More Events To Report</h1>
              <h3>You Are Now Up-To-Date</h3>
              <img
                style={{ margin: '48px 0' }}
                width={120}
                src="/images/pages/simulation/welcome/checklist.png"
                alt="Event"
              />
              <p>Please Proceed to the National Briefing</p>
              <Button type="primary" onClick={handleStartCountry}>
                Go To National Briefing
              </Button>
            </div>
          </Card>
        );
      }

      const eventGroup = eventGroupList.find(eg => eg.eventGroupId === event.eventGroupId);
      const resource = resourceList.find(r => r.resourceId === event.resourceId);
      const eventDetails = EVENT_DETAILS_MAP[event.eventGroupItemId];
      let iconUrl = eventDetails?.iconUrl;
      if (!iconUrl) {
        const iconName = resource.iconUrl.split('/').pop();
        iconUrl = `/images/icons/resources/${iconName}`;
      }

      if (event.description === 'Fortuitous Event') {
        return (
          <Card title={title} extra={flag}>
            <div className="d-flex flex-column align-center">
              <h1>A {event.description.toUpperCase()} HAS OCCURRED</h1>
              <div className="text-center" style={{ margin: '48px 0' }}>
                <h3>
                  {eventDetails ? (
                    <>{eventDetails.title}</>
                  ) : (
                    <>{eventGroup.eventGroupName} Results In Useful Resource</>
                  )}
                </h3>
                <img src={iconUrl} width={96} alt="Event" />
                <h3 style={{ color: 'green' }}>
                  {eventDetails ? (
                    <>
                      {event.transactionQuantity} {eventDetails.result}
                    </>
                  ) : (
                    <>
                      {event.transactionQuantity} {resource.resourceNameLocalized} Units Gained
                    </>
                  )}
                </h3>
              </div>
              <Button type="primary" onClick={handleStartCountry}>
                Continue
              </Button>
            </div>
          </Card>
        );
      } else if (event.description === 'Medical Disaster Event') {
        return (
          <Card title={title} extra={flag}>
            <div className="d-flex flex-column align-center">
              <h1>A {event.description.toUpperCase()} HAS OCCURRED</h1>
              <div className="text-center" style={{ margin: '48px 0' }}>
                <h3>
                  {eventDetails.title} - {Math.abs(event.transactionQuantity)} people affected
                </h3>
                <img src={iconUrl} width={96} alt="Event" />
                <h3 style={{ color: 'red' }}>{eventDetails.result}</h3>
              </div>
              <Button type="primary" onClick={handleStartCountry}>
                Continue
              </Button>
            </div>
          </Card>
        );
      } else {
        return (
          <Card title={title} extra={flag}>
            <div className="d-flex flex-column align-center">
              <h1>A {event.description.toUpperCase()} HAS OCCURRED</h1>
              <div className="text-center" style={{ margin: '48px 0' }}>
                <h3>{eventDetails ? <>{eventDetails.title}</> : <>{eventGroup.eventGroupName}</>}</h3>
                <img src={iconUrl} width={96} alt="Event" />
                <h3 style={{ color: 'red' }}>
                  {eventDetails ? (
                    <>
                      {Math.abs(event.transactionQuantity)} {eventDetails.result}
                    </>
                  ) : (
                    <>
                      {Math.abs(event.transactionQuantity)} {resource.resourceNameLocalized} Unusable Until Further
                      Notice.
                    </>
                  )}
                </h3>
              </div>
              <Button type="primary" onClick={handleStartCountry}>
                Continue
              </Button>
            </div>
          </Card>
        );
      }
    }
  };

  return (
    <>
      {step === 1 && (
        <Card title={title} extra={flag}>
          <p>
            SIMPOLICON is a realistic portrayal of the complex process and problems of national economic development.
          </p>
          <p>
            Your country has been given a limited amount of productive resources such as various types of agricultural{' '}
            <b>Land</b> and mineral <b>Deposits</b>, <b>Water Resources</b>, <b>Unskilled Labor</b>, and{' '}
            <b>Hand Tools</b>.
          </p>
          <p>
            You are one of your country's leading economic and political experts, and you must make decisions on the
            best ways to use your country's resources. Your goal is to create a stable, secure country with a
            well-balanced economy while maintaining a healthy and sustainable environment.
          </p>
          <p>
            Your decisions will determine production from agricultural land and mineral deposits; the education, health,
            and happiness of the population; the creation of machines, equipment, and other capital goods; and the
            military security and disaster preparedness of your country as a whole.
          </p>
          <p>
            Your challenge is to meet your country's social, economic, and environmental objectives in an efficient,
            sustainable manner. The key to meeting this challenge is to balance these unlimited economic wants with the
            need to conserve resources and limit pollution.
          </p>
          <p>Good luck!</p>
          <div className="d-flex flex-column align-center">
            {!simulation?.simulationYearArray?.length && (
              <p style={{ color: 'red' }}>This simulation has zero years. Please contact your teacher.</p>
            )}
            {simulation?.simulationYearArray?.length && !isYearStarted && (
              <p>The current year has not started. Please contact your teacher.</p>
            )}
            {simulation?.simulationYearArray?.length && isYearStarted && <p>Press 'Start Country' to Begin</p>}
            <Button type="primary" onClick={handleStartCountry} disabled={!isYearStarted}>
              Start Country
            </Button>
          </div>
        </Card>
      )}
      {step === 2 && (
        <Card title={title} extra={flag}>
          <div className="d-flex flex-column align-center">
            <h1>IT'S A NEW YEAR</h1>
            <img style={{ margin: '48px 0' }} src="/images/pages/simulation/welcome/fireworks.png" alt="Event" />
            <p>Press 'Begin' to Get a National Update</p>
            <Button type="primary" onClick={handleStartCountry}>
              Begin
            </Button>
          </div>
        </Card>
      )}
      {step === 3 && renderEvents()}
      {
        <MedicalHelpModal
          open={isMedicalHelpModalOpen}
          totalNeed={Math.abs(events[eventIndex]?.transactionQuantity ?? 0)}
          onSave={handleSaveMedicalBox}
          onClose={() => setMedicalHelpModalOpen(false)}
        />
      }
    </>
  );
};
