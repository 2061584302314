import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Table } from 'antd';
import { GDPTooltip } from '../../../../components/GDPTooltip';
import { NationalWealthTooltip } from '../../../../components/NationalWealthTooltip';
import ApiService from '../../../../services/ApiService';
import DataService from '../../../../services/DataService';
import Utils from '../../../../utils';
import { Page } from './styles';

const WORLD_BRIEFING_COLUMNS = [
  {
    title: '',
    dataIndex: 'type',
    rowScope: 'row',
  },
  {
    id: 'worldWealth',
    title: 'World Wealth',
    render: row => row.wealth.toFixed(0),
  },
  {
    id: 'worldPopulation',
    title: 'World Population',
    render: row => row.population.toFixed(0),
  },
  {
    id: 'worldResources',
    title: 'World Resources',
    render: row => row.resources.toFixed(0),
  },
  {
    id: 'worldWater',
    title: 'World Water',
    render: row => row.water.toFixed(0),
  },
  {
    id: 'worldPollution',
    title: 'World Pollution',
    render: row => row.pollution.toFixed(0),
  },
  {
    id: 'worldGold',
    title: 'World Gold',
    render: row => row.gold.toFixed(0),
  },
  {
    id: 'worldMilitary',
    title: 'World Military',
    render: row => row.military.toFixed(0),
  },
  {
    id: 'worldDeaths',
    title: 'World Deaths',
    render: row => row.deaths.toFixed(0),
  },
];

export const WorldBriefing = () => {
  const params = useParams();
  const countryList = DataService.getItem('countryList');
  const [worldBriefingData, setWorldBriefingData] = useState([]);
  const [simulationCountries, setSimulationCountries] = useState([]);

  const SC_COLUMNS = [
    {
      id: 'country',
      title: 'Country',
      render: row => (
        <>
          <img src={`/images/flags/${row.country.countryCode}.png`} alt="" />
          <span style={{ paddingLeft: 4 }}>{row.country.countryName}</span>
        </>
      ),
      sorter: (a, b) => a.country.countryName.localeCompare(b.country.countryName),
    },
    {
      id: 'nationalWealth',
      title: <NationalWealthTooltip showIcon />,
      render: row => row.nationalWealth.toFixed(0),
      sorter: (a, b) => (a.nationalWealth > b.nationalWealth ? 1 : -1),
    },
    {
      id: 'gdp',
      title: <GDPTooltip showIcon />,
      render: row => row.gdp.toFixed(0),
      sorter: (a, b) => (a.gdp > b.gdp ? 1 : -1),
    },
    {
      id: 'population',
      title: 'Population',
      render: row => row.population.toFixed(0),
      sorter: (a, b) => (a.population > b.population ? 1 : -1),
    },
    {
      id: 'gdpPerCapita',
      title: 'GDP Per Capita',
      render: row => row.gpc.toFixed(0),
      sorter: (a, b) => (a.gpc > b.gpc ? 1 : -1),
    },
    {
      id: 'resources',
      title: 'Resources',
      render: row => row.resources.toFixed(0),
      sorter: (a, b) => (a.resources > b.resources ? 1 : -1),
    },
    {
      id: 'water',
      title: 'Water',
      render: row => row.water.toFixed(0),
      sorter: (a, b) => (a.water > b.water ? 1 : -1),
    },
    {
      id: 'pollution',
      title: 'Pollution',
      render: row => row.pollution.toFixed(0),
      sorter: (a, b) => (a.pollution > b.pollution ? 1 : -1),
    },
    {
      id: 'deaths',
      title: 'Deaths',
      render: row => Math.abs(row.deceasedPopulation).toFixed(0),
      sorter: (a, b) => (a.deceasedPopulation > b.deceasedPopulation ? 1 : -1),
    },
    {
      id: 'score',
      title: 'Score',
      render: row => row.simulationCountryScore.toFixed(0),
      sorter: (a, b) => (a.simulationCountryScore > b.simulationCountryScore ? 1 : -1),
    },
  ];

  const fetchSimulationCountry = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    data = await ApiService.retrieveSimulationCountryList(data.result.simulationId);

    const scList = data.resultList;
    const wbTotalValues = {
      type: 'Total',
      wealth: 0,
      population: 0,
      resources: 0,
      water: 0,
      pollution: 0,
      gold: 0,
      military: 0,
      deaths: 0,
    };
    scList.forEach(sc => {
      const resourceValues = Utils.calculateResourceValues(sc.simulationCountryResourceViewList);
      sc.nationalWealth = resourceValues.totalWealth;
      sc.resources = resourceValues.totalNatural;
      sc.water = resourceValues.totalWater;
      sc.country = countryList.find(c => c.countryId === sc.countryId);

      wbTotalValues.wealth += resourceValues.totalWealth;
      wbTotalValues.population += sc.population;
      wbTotalValues.resources += resourceValues.totalNatural;
      wbTotalValues.water += resourceValues.totalWater;
      wbTotalValues.pollution += sc.pollution;
      wbTotalValues.gold += resourceValues.totalGold;
      wbTotalValues.military += resourceValues.militaryPower;
      wbTotalValues.deaths -= sc.deceasedPopulation;
    });
    scList.sort((a, b) => a.country.countryName.localeCompare(b.country.countryName));
    setSimulationCountries(scList);

    const wbData = [];
    const count = scList.length;
    wbData.push(wbTotalValues);
    if (scList.length > 0) {
      wbData.push({
        type: 'AVG',
        wealth: wbTotalValues.wealth / count,
        population: wbTotalValues.population / count,
        resources: wbTotalValues.resources / count,
        water: wbTotalValues.water / count,
        pollution: wbTotalValues.pollution / count,
        gold: wbTotalValues.gold / count,
        military: wbTotalValues.military / count,
        deaths: wbTotalValues.deaths / count,
      });
    } else {
      wbData.push({
        ...wbTotalValues,
        type: 'Avg',
      });
    }

    setWorldBriefingData(wbData);
  };

  useEffect(() => {
    fetchSimulationCountry();
    // eslint-disable-next-line
  }, []);

  return (
    <Page>
      <Card size="small" title="World Briefing">
        <Table
          style={{ border: '4px solid #888' }}
          size="small"
          pagination={false}
          rowKey="type"
          columns={WORLD_BRIEFING_COLUMNS}
          dataSource={worldBriefingData}
        />
        {/* <hr className="mt-16" style={{ height: 32, background: '#888' }} /> */}
        <Table
          className="mt-16"
          style={{ marginTop: 24, border: '4px solid #888' }}
          size="small"
          pagination={false}
          rowKey="simulationCountryId"
          columns={SC_COLUMNS}
          dataSource={simulationCountries}
        />
      </Card>
    </Page>
  );
};
