import { useEffect, useState } from 'react';
import { Card, Tabs } from 'antd';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { Overview } from './Overview';
import { MasterList } from './MasterList';
import { Resources } from './Resources';
import { EconomicGoals } from './EconomicGoals';
import { KeyDataByYear } from './KeyDataByYear';
import { EvaluationScore } from './EvaluationScore';
import { TransactionHistory } from './TransactionHistory';
import { useSimulationContext } from '../../../../contexts/SimulationContext';

const TAB_ITEMS = [
  {
    key: 'overview',
    label: 'Overview',
  },
  {
    key: 'master-list',
    label: 'Master List',
  },
  {
    key: 'resources-products',
    label: 'Resources & Products',
  },
  {
    key: 'economic-goals',
    label: 'Economic Goals',
  },
  {
    key: 'key-data-by-year',
    label: 'Key Data By Year',
  },
  {
    key: 'evaluation-score',
    label: 'Evaluation & Score',
  },
  {
    key: 'transaction-history',
    label: 'Transaction History',
  },
];

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    color: #fff;
  }

  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //   color: #333 !important;
  // }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    max-height: calc(100vh - 140px);
    overflow-y: auto;
  }
`;

export const NationalBriefing = () => {
  const { overviewLoaded, calculateSimulationCountryGoals } = useSimulationContext();
  const [goalRanks, setGoalRanks] = useState([]);
  const defaultIndex = overviewLoaded ? 2 : 0;
  const [activeKey, setActiveKey] = useState(TAB_ITEMS[defaultIndex].key);

  const loadData = async () => {
    const { goalRanks: grs } = await calculateSimulationCountryGoals();
    setGoalRanks(grs);
  };

  const handleChangeTab = key => {
    if (['economic-goals', 'evaluation-score'].includes(key)) {
      if (goalRanks.length === 0 || goalRanks[0] === -1) {
        Swal.fire({
          icon: 'info',
          text: 'Data not available until Goals have been set.',
        });
        return;
      }
    }
    setActiveKey(key);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  const tabs = (
    <StyledTabs hideAdd type="card" size="small" items={TAB_ITEMS} activeKey={activeKey} onChange={handleChangeTab} />
  );

  return (
    <StyledCard size="small" title="National Briefing" extra={tabs}>
      {activeKey === TAB_ITEMS[0].key && <Overview />}
      {activeKey === TAB_ITEMS[1].key && <MasterList />}
      {activeKey === TAB_ITEMS[2].key && <Resources />}
      {activeKey === TAB_ITEMS[3].key && <EconomicGoals />}
      {activeKey === TAB_ITEMS[4].key && <KeyDataByYear />}
      {activeKey === TAB_ITEMS[5].key && <EvaluationScore />}
      {activeKey === TAB_ITEMS[6].key && <TransactionHistory />}
    </StyledCard>
  );
};
