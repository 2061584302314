import { Card, Modal } from 'antd';

export const NotesModal = ({ open, onClose }) => (
  <Modal width="80%" title="Notes" footer={null} open={open} onCancel={onClose}>
    <Card
      className="mt-16"
      size="small"
      title="Notes on the allocation of population, land resources, water resources, and mineral deposits, 3/3/18:"
    >
      In allocating population and natural resources, great effort was made to simulate current real world conditions on
      a proportional scale. It was necessary to use a variety of sources, from different years, and in some cases, using
      different measurements. In many cases, only data for countries with a significant amount of a particular resource
      were available, with other countries grouped together as “other.” In almost all cases, production data was used
      since there was no reliable worldwide data on “reserves” for all countries. However, production data was used only
      as a means of allocating resources and does not indicate that a simulated country actually can or will produce
      anything from that resource. It should be further noted, that in the more than 25 years that the Simpolicon©
      simulation has been conducted, countries that have fewer starting resources have been as successful as countries
      that start with an abundance of resources. Such results were due to student planning, trade negotiations, and
      overall effort.
    </Card>
    <Card className="mt-16" size="small" title="Population:">
      The simulated population for Simpolicon© countries was determined by taking a country’s real world population and
      dividing by 1,000,000. For example, China with a real-world population of approximately 1,300,000,000 has a
      simulated population of 1300; Brazil with approximately 205,000,000 people has a simulated population of 205;
      Italy with 61,000,000 has 61; Mali with 14,000,000 has 14; and so forth. Out of the almost 200 countries that are
      members of the United Nations, only about 12 countries have real-world populations over 100,000,000 people;
      approximately 70 countries have real-world populations between 10,000,000 and 100,000,000; slightly over 110
      countries have real-world populations of less than 10,000,000, and of those, over 40 countries have real-world
      populations of less than 1,000,000 people. Countries with real-world populations of less than 10,000,000 have
      simulated populations of 9 people. Through past experience, this is the absolute minimum starting population a
      country needs for the simulation to be instructive. Although this is not empirically accurate in terms of the
      proportions calculated above, it allows those countries to participate fully in the simulation, and it does
      reflect the concept of relative population size. Population data was obtained from a variety of sources, including
      CIA World Factbook, United Nations Population Division, The World Bank, Index Mundi, and Wikipedia.
    </Card>
    <Card className="mt-16" size="small" title="Land Resources:">
      All Land Resource allocations are proportionate to each other based on the number of hectares each represents in
      the real world as determined by the Food and Agriculture Organization of the United Nations (FAOSTAT), using data
      available from 2009 to 2012. Other sources were also consulted, mainly Index Mundi, which references the United
      States Department of Agriculture as its source, and occasionally Wikipedia. All numbers are approximate since the
      number of hectares in production changes from year to year. Also, some countries are not listed on the FAOSTAT
      data for a particular land resource, but are mentioned in the CIA World Factbook as having that particular
      resource; in those cases, a token amount of that resource was allocated.
      <br />
      <br />
      Forests cover approximately 4,000,000,000 hectares in the real world, the largest area of any land resource.
      Russia, the real-world country with the most forest area (809,030,000 hectares) was allocated 1,000,000 Forest
      Land units in Simpolicon© . Other countries received Forest Land units based on their actual hectares relative to
      Russia’s and relative to the 1,000,000 maximum Simpolicon© units. So, for example, Brazil with 521,716,000
      hectares of forests, 64.48% of Russia’s amount, was allocated 644,890 Forest Land units (64.48% of 1,000,000).
      <br />
      <br />
      Other Land Resources’ maximum Simpolicon© units are relative to Forest Land. For example, there are approximately
      33,385,000 hectares of cotton land in the real world, or approximately .8346% of the total forest area. So the
      maximum Cotton Land allocated to India, the country with the most cotton land in production, was 8350 units
      (.8346% of 1,000,000—rounding was done to simplify the process). Other countries would receive Cotton Land based
      on their actual hectares relative to India’s and in proportion to the 8350 maximum. So for example, Afghanistan
      with 50,000 hectares of cotton land, or .462% of India’s 10,800,000 hectares, was allocated 39 Cotton Land units,
      (.462% of the 8350 maximum).
    </Card>
    <Card
      className="mt-16"
      size="small"
      title="Grains Lands, Grazing Land, Recreation Land, Riparian Land, Specialty Foods Land, and Tubers Land:"
    >
      Grains Land was calculated from FAOSTAT data (2012). Hectares in production for each country for each of the
      following crops were added together to arrive at the total Grains Land allocation: barley, buckwheat, cereals not
      elsewhere specified, fonio, maize/corn, millet, mixed/coarse grains, oats, quinoa, rice, rye, sorghum, triticale,
      and wheat.
      <br />
      <br />
      Grazing Land was calculated from FAOSTAT data (2009) using hectares of “Permanent Meadows and Pastures” for each
      country. If the amount in Simpolicon© units allocated seemed insufficient based on these data, the number of
      hectares was compared to the total number of sheep, goats, and cattle for each country (FAOSTAT 2010), and
      adjustments were made to the Simpolicon© allocations such that a bare minimum of Grazing Land was allocated for
      that amount of livestock.
      <br />
      <br />
      Recreational Land: Since it is difficult to determine how much “Recreational Land” a country has, “tourism dollars
      per $1000 of GDP” was used as compiled by NationMaster.com, World Development Indicators database. Recreational
      Land includes beaches, mountains, rock formations, plains, deserts, forests, and so forth. Many small countries
      rely on basic recreational land to attract tourism which may be a major source of income. They can then use that
      income to purchase other products needed for development. The allocation of Recreational Land based on tourism
      means that a large country (like the United States), with a lot of recreational land, may have more tourism
      dollars actually spent, but those dollars are a smaller percentage of GDP. In contrast, a very small country (like
      Aruba) may have less recreational land and fewer total tourism dollars spent, but that amount represents a much
      larger percentage of GDP. In Simpolicon, to level the playing field and to give countries that depend on tourism a
      chance to develop, countries like Aruba are allocated more Recreational Land than the United States. Recreational
      Land, when combined with basic tourism facilities, generate Tourism Tickets which earn income for the country
      selling them in trade and bonus points as Foreign Tourism Tickets for the country purchasing them.
      <br />
      <br />
      Riparian Land was calculated using CIA World Factbook (2008-2012) and FAOSTAT (2009) data for the surface area of
      inland water (lakes, reservoirs, rivers) plus coastline. There were approximately 40 landlocked countries and 50
      countries without any measurable inland water.
      <br />
      <br />
      Specialty Foods Land includes land suitable for growing bananas, cocoa, coconuts, coffee, grapes, groundnuts
      (peanuts), nuts, olives, palm oil, spices, sugar, and tea.
      <br />
      Each country that grows one of these foods was given a score based on the quantity produced relative to other
      countries. These totals were then multiplied by a constant to determine their allocations of Specialty Foods Land.
      Some countries, like India, grow many of these specialty foods, and therefore have a large allocation in
      Simpolicon. Other countries may only have one of these specialty foods and therefore only receive a minimal
      allocation. These allocations are not in proportion to other types of land in Simpolicon since the products are so
      diverse, and one large crop may completely outstrip the combined production of many others. The purpose of
      including Specialty Foods Land in Simpolicon is to allow countries that depend on non-essential food products for
      much of their income to have a chance of succeeding in their development plans. When these Specialty Foods are
      sold in trade, they earn income for the country selling them and bonus points as Imported Specialty Foods for the
      country buying them.
      <br />
      <br />
      Tubers Land was calculated from FAOSTAT data (2010). Hectares in production for each country for each of the
      following crops were added together to arrive at the total Tubers Land allocation: cassava, potatoes, roots and
      tubers not elsewhere specified, sweet potatoes, taro (cocoyam), yams, yautia (cocoyam), and plantains. Plantains,
      while not tubers, were included in this category in order to allow for many countries that also rely on starchy
      fruits and vegetables as a main staple of their diet.
    </Card>
    <Card className="mt-16" size="small" title="Water Resources:">
      Water Resources are the sum of surface water (sq. km) of all inland water bodies such as lakes, reservoirs, and
      rivers using data from the CIA World Factbook (2008-2012). The allocation of Water Resources for each country was
      calculated using Canada’s water area (891163 sq. km., the world’s largest) and dividing it into 50 sections to
      determine a section size of 17823. Other countries in the world would fall into one of these sections based on the
      amount of their water area. This result was then multiplied by a constant 2500 to arrive at the Water Resources
      units for Simpolicon. Canada, for example, would look like this: 891163 / 50 = 17823; 891163 / 17823 = 50 * 2500 =
      125002 Water Resources units. A country with limited water resources (Switzerland) would have this calculation:
      1280 sq. km. / 17823 = .07 * 2500 = 180 Water Resources. A country with very limited water resources generating an
      allocation less than 10 units by this formula would receive a minimum 10 Water Resources units, enough to supply
      20 people with their water needs. These Water Resources are assumed to be Non-Potable Water unless obtained from
      deep wells or from a Water Treatment Facility in which case they are considered Potable Water. Water Resources
      regenerate each year within a 10% random amount.
    </Card>
    <Card className="mt-16" size="small" title="Mineral Deposits:">
      All Mineral Deposit allocations are proportionate to each other based on the number of metric tons of production
      each represents in the real world as determined by data from the British Geological Survey (BGS), 2010, the United
      States Geological Survey (USGS), 2009, and the U.S. Energy Information Administration. Other sources were also
      consulted, mainly Index Mundi, which references the United States Geological Survey Mineral Resources Program as
      its source, and occasionally Wikipedia. All numbers are approximate since the number of metric tons in production
      changes from year to year. Some countries do not appear in the BGS or USGS data, but are mentioned in the CIA
      World Factbook as having that particular mineral deposit; in those cases, a token amount of that deposit was
      allocated.
      <br />
      <br />
      Coal Deposits have the highest number allocated in Simpolicon© with 1,000,000 units to China, the country with
      the most current coal production in the real world (3,240,000,000 metric tons). Other countries received Coal
      Deposit units based on their production in relation to China’s and relative to the 1,000,000 Simpolicon© units.
      For example, Mozambique, with only 25,000 metric tons of coal production (.00077% of China’s), was allocated
      .00077% of 1,000,000 = 8 Simpolicon© Coal Deposits.
      <br />
      <br />
      Other Mineral Deposits’ maximum Simpolicon© units are relative to Coal Deposits. For example, worldwide copper
      production was 16,221,350 metric tons, only .2245% of total worldwide coal production of 7,223,964,054 metric
      tons. Chile, the real-world leader in copper production was allocated 2250 Copper Deposits in Simpolicon© (.2245%
      of 1,000,000). Other countries would get their Copper Deposit allocation based on their copper production relative
      to Chile’s and in proportion to the maximum of 2250 Copper Deposit units. For example, Sweden’s 76,514 metric tons
      of copper production is only 1.41% of Chile’s production (5,418,900), so Sweden received 1.41% of 2250, or 32
      Copper Deposits in Simpolicon©.
      <br />
      <br />
      Gold Deposits. Since gold production is measured in kilograms, rather than metric tons, countries would have
      received only fractional Simpolicon© Gold Deposits if the proportionate calculations above were followed. Thus,
      resulting calculations were multiplied by 1,000 so that countries would have more realistic allocations of Gold
      Deposits ranging from 1 unit to 351 units. Countries that were not listed as having gold production in the BGS
      data, but were said to have gold resources in the CIA World Factbook, received a token 1 unit.
      <br />
      <br />
      High Tech Minerals Deposits is a composite category including the following high tech minerals: antimony, arsenic,
      beryllium, cadmium, cesium, gallium, germanium, indium, lithium, mica, platinum group, rare earths, rubidium,
      strontium, tantalum, tellurium, tungsten, yttrium, and others. For each of those minerals found in a country a
      score was given depending on how much of that mineral a country had relative to other countries. The total score
      was then multiplied by a constant to determine the total simulation units allocated to each country. This
      allocation is not proportional to the allocation of other mineral deposits since these high tech minerals are
      often found in very small quantities.
    </Card>
  </Modal>
);
