import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Col, Collapse, Row } from 'antd';
import Swal from 'sweetalert2';
import { GDPTooltip } from '../../../../components/GDPTooltip';
import { useSimulationContext } from '../../../../contexts/SimulationContext';
import DataService from '../../../../services/DataService';
import Utils from '../../../../utils';
import { NationalWealthTooltip } from '../../../../components/NationalWealthTooltip';

export const Overview = () => {
  const { overviewLoaded, simulationCountry, setOverviewLoaded } = useSimulationContext();
  const countryList = DataService.getItem('countryList');
  const [data, setData] = useState(null);

  const sections = [
    {
      key: 'general-information',
      label: 'General Information',
      children: data ? (
        <>
          <p>
            <strong>Name: </strong>
            {data.countryName}
          </p>
          <p>
            <strong>Capital: </strong>
            {data.capitalCity}
          </p>
          <p>
            <strong>Longitude: </strong>
            {data.longitude.toFixed(2)}
          </p>
          <p>
            <strong>Latitude: </strong>
            {data.latitude.toFixed(2)}
          </p>
        </>
      ) : null,
    },
    {
      key: 'simulation-statistics',
      label: 'Simulation Statistics',
      children: data ? (
        <>
          <p>
            <strong>
              <NationalWealthTooltip title="National Wealth: " />
            </strong>
            {data.nationalWealth.toFixed(0)}
          </p>
          <p>
            <strong>
              <GDPTooltip title="Gross Domestic Product (GDP): " />
            </strong>
            {data.gdp.toFixed(0)}
          </p>
          <p>
            <strong>Population: </strong>
            {data.population.toFixed(0)}
          </p>
          <p>
            <strong>GDP Per Capita: </strong>
            {data.gpc.toFixed(0)}
          </p>
          <p>
            <strong>Water Resources: </strong>
            {data.water.toFixed(0)}
          </p>
          <p>
            <strong>Land & Mineral Resources: </strong>
            {data.landAndMineral.toFixed(0)}
          </p>
          <p>
            <strong>Pollution: </strong>
            {data.pollution.toFixed(0)}
          </p>
          <p>
            <strong>Deaths to Date: </strong>
            {Math.abs(data.deaths).toFixed(0)}
          </p>
          <p>
            <strong>Military Points: </strong>
            {data.military.toFixed(0)}
          </p>
          <p>
            <strong>Gold: </strong>
            {simulationCountry?.gold.toFixed(0)}
          </p>
          <p>
            <strong>Score: </strong>
            {data.score.toFixed(0)}
          </p>
        </>
      ) : null,
    },
  ];
  const activeKeys = sections.map(section => section.key);

  const loadData = () => {
    const result = {};

    const sc = simulationCountry;
    const country = countryList.find(c => c.countryId === sc.countryId);

    result.countryName = country.countryName;
    result.capitalCity = country.capitalCity;
    result.longitude = country.longitude;
    result.latitude = country.latitude;

    result.gdp = sc.gdp;
    result.population = sc.population;
    result.gpc = sc.gpc;
    result.pollution = sc.pollution;
    result.deaths = sc.deceasedPopulation;
    result.score = sc.simulationCountryScore;

    const resourceValues = Utils.calculateResourceValues(sc.simulationCountryResourceViewList);

    result.nationalWealth = resourceValues.totalWealth;
    result.water = resourceValues.totalWater;
    result.landAndMineral = resourceValues.totalNatural;
    result.military = resourceValues.militaryPower;
    result.gold = resourceValues.totalGold;

    setData(result);
  };

  useEffect(() => {
    if (simulationCountry) {
      if (!simulationCountry.goalsAreLocked && !overviewLoaded) {
        Swal.fire({
          icon: 'info',
          title: 'First Order of Business',
          text: 'Set Your Goals',
        });
      }
      setOverviewLoaded(true);
      loadData();
    }
    // eslint-disable-next-line
  }, [simulationCountry]);

  if (!data) {
    return null;
  }

  return (
    <Row gutter={4}>
      <Col span={12}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBDj3jEpQm8jWTwkRWI7J8X2sPeXhdRghA' }}
          center={{ lat: data?.latitude, lng: data?.longitude }}
          defaultZoom={7}
        />
      </Col>
      <Col span={12}>
        <Collapse defaultActiveKey={activeKeys} items={sections} />
      </Col>
    </Row>
  );
};
