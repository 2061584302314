import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { Layout } from 'antd';
import { CreditsModal } from '../../components/CreditsModal';
import { HelpModal } from '../../components/HelpModal';
import { HintsModal } from '../../components/HintsModal';
import { NotesModal } from '../../components/NotesModal';
import { QuickStartModal } from '../../components/QuickStartModal';
import { ResourceDetailsModal } from '../../components/ResourceDetailsModal';
import ApiService from '../../services/ApiService';
import DataService from '../../services/DataService';
import SessionService from '../../services/SessionService';
import { DATA_LIST } from './data';
import { MainLayout, StyledContent } from './styles';
import { AppHeader } from './AppHeader';
import { AppMenu } from './AppMenu';
import { DataLoader } from './DataLoader';
import { Summary } from './Summary';
import { SimulationContextProvider } from '../../contexts/SimulationContext';

export const Simulation = () => {
  const navigate = useNavigate();
  const params = useParams();
  const username = SessionService.getUsername();
  const [isQuickStartModalOpen, setQuickStartModalOpen] = useState(false);
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [isHintsModalOpen, setHintsModalOpen] = useState(false);
  const [isCreditsModalOpen, setCreditsModalOpen] = useState(false);
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);
  const [isDataLoaded, setDataLoaded] = useState(!!DataService.getItem(DATA_LIST[DATA_LIST.length - 1].key));
  const [simulation, setSimulation] = useState(null);

  const handleCompleteDataLoading = () => {
    setDataLoaded(true);
  };

  const fetchSimulationCountry = async () => {
    let data = await ApiService.retrieveSimulationCountry(params.simulationCountryId);
    if (!data.success) {
      navigate('/portal/welcome');
      return;
    }

    data = await ApiService.retrieveSimulation(data.result.simulationId);
    setSimulation(data.result);
  };

  useEffect(() => {
    fetchSimulationCountry();
    // eslint-disable-next-line
  }, []);

  if (!isDataLoaded) {
    return <DataLoader onComplete={handleCompleteDataLoading} />;
  }

  if (!simulation) {
    return null;
  }

  const headerMenuItems = [
    {
      key: 'quick-start',
      label: <Link onClick={() => setQuickStartModalOpen(true)}>Quick Start</Link>,
    },
    {
      key: 'help',
      label: <Link onClick={() => setHelpModalOpen(true)}>How to Play Details</Link>,
    },
    {
      key: 'hints',
      label: <Link onClick={() => setHintsModalOpen(true)}>Hints</Link>,
    },
    {
      key: 'credits',
      label: <Link onClick={() => setCreditsModalOpen(true)}>Credits</Link>,
    },
    {
      key: 'notes',
      label: <Link onClick={() => setNotesModalOpen(true)}>Notes</Link>,
    },
  ];

  return (
    <SimulationContextProvider>
      <MainLayout>
        <AppHeader initial={username[0]} headerMenuItems={headerMenuItems} />
        <Summary />
        <Layout>
          <AppMenu />
          <Layout>
            <StyledContent>
              <Outlet />
            </StyledContent>
          </Layout>
        </Layout>
        <QuickStartModal open={isQuickStartModalOpen} onClose={() => setQuickStartModalOpen(false)} />
        <HelpModal open={isHelpModalOpen} onClose={() => setHelpModalOpen(false)} />
        <HintsModal open={isHintsModalOpen} onClose={() => setHintsModalOpen(false)} />
        <CreditsModal open={isCreditsModalOpen} onClose={() => setCreditsModalOpen(false)} />
        <NotesModal open={isNotesModalOpen} onClose={() => setNotesModalOpen(false)} />
      </MainLayout>
      <ResourceDetailsModal />
    </SimulationContextProvider>
  );
};
