import { Card, Modal } from 'antd';

export const HintsModal = ({ open, onClose }) => (
  <Modal width="80%" title="Hints" footer={null} open={open} onCancel={onClose}>
    <Card className="mt-16" size="small">
      1. In Production, a production factor value may be exceeded. For example, if the factor value is 10, you may enter
      factors inputs which when multiplied together exceed 10. However, you will not get any extra output as a result,
      but you will have wasted some inputs. Also, no fractional input values are accepted.
      <br />
      <br />
      2. Some production techniques generate pollution. It is usually easier to prevent pollution, using an Ecologist or
      Pollution Control Device, than it is to clean up pollution using Pollution Clean-Up units once it has occurred.
      <br />
      <br />
      3. Optional Inputs may be used to increase production output and/or increase or decrease potential pollution
      depending on which optional inputs are used. Hover your cursor over the specific optional input icon to see a tool
      tip explaining their effect.
      <br />
      <br />
      4. Educating people does not add to your population. The person changes to a higher educational level, now able to
      do different or more productive tasks.
      <br />
      <br />
      5. A military person in your country may not do civilian work, so think carefully if you build a military. Be sure
      to read the Rules of War by clicking "Military" on the dashboard and choosing the Rules of War tab.
      <br />
      <br />
      6. Many production items, as in real life, have a limited useful lifetime or have a limited number of uses in a
      given time. Such depreciation of capital must be taken into account. Click on National Briefing and the tab
      "Master List" to see the Annual Uses and Lifetime uses of these products and people. For example, one Small
      Machine has a maximum of 5 annual uses and 15 lifetime uses. When the annual uses have been used, they come back
      each year until the lifetime uses have been exhausted. At that point, it no longer exists. Many other products,
      such as Coal, Fish, or Wire, come in bulk and their quantity, annual uses, and lifetime uses are the same, and
      their uses do not come back each year.
      <br />
      <br />
      7. When their lifetime uses have been reached, People become "Retired Population" and must be fed, but no longer
      work.
      <br />
      <br />
      8. Information Systems, Power Systems, Transportation Systems, and Complete Communications Systems, once they have
      been built and have used their lifetime uses, will still exist and may be "renewed" with fewer factor inputs. They
      also may be rebuilt from scratch.
      <br />
      <br />
      9. Computer-generated disasters may occur at any time, so be prepared in advance by building health facilities and
      educating Doctors. It's too late to produce them for useful effect after the disaster.
      <br />
      <br />
      10. Tourism Tickets and Specialty Foods purchased in international trade earn bonus points.
    </Card>
  </Modal>
);
