import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Tooltip } from 'antd';
import InputNumber2 from '../../../../components/InputNumber2/InputNumber2';
import { useSimulationContext } from '../../../../contexts/SimulationContext';

const FactorScoreCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  width: 64px;
  height: 64px;
  text-align: center;
  background-color: ${props => (props.$isAllowed ? '#c1ffc9' : '#ffc4c6')};
`;

const InputScoreCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  width: 64px;
  height: 64px;
  text-align: center;
  background-color: ${props => (props.$isAllowed ? '#c1ffc9' : '#ffc4c6')};
`;

export const RecipeCard = ({ scResources, data, repeatCount, index, onChange }) => {
  const { resourceList, setSelectedResourceId } = useSimulationContext();
  const [primaryResourceCount, setPrimaryResourceCount] = useState(0);
  const [secondaryResourceCount, setSecondaryResourceCount] = useState(0);

  const primaryResource = resourceList.find(r => r.resourceId === data.PrimaryResourceIdUsed);
  const secondaryResource = resourceList.find(r => r.resourceId === data.SecondaryResourceIdUsed);

  const primarySCResource = scResources.find(scr => scr.resourceId === data.PrimaryResourceIdUsed);
  const secondarySCResource = scResources.find(scr => scr.resourceId === data.SecondaryResourceIdUsed);

  const primaryResourceIconName = primaryResource.iconUrl.split('/').pop();
  const primaryResourceIconUrl = `/images/icons/resources/${primaryResourceIconName}`;
  const secondaryResourceIconName = secondaryResource ? secondaryResource.iconUrl.split('/').pop() : '';
  const secondaryResourceIconUrl = `/images/icons/resources/${secondaryResourceIconName}`;

  const isFactorAllowed =
    primarySCResource.currentSimulationYearRemainingUses *
      (secondarySCResource?.currentSimulationYearRemainingUses ?? 1) >=
    data.ProductRequired;
  const isInputAllowed =
    primaryResourceCount * (secondaryResource ? secondaryResourceCount : 1) >= data.ProductRequired;

  useEffect(() => {
    onChange(index, primaryResourceCount, secondaryResourceCount);
    // eslint-disable-next-line
  }, [primaryResourceCount, secondaryResourceCount]);

  useEffect(() => {
    setPrimaryResourceCount(0);
    setSecondaryResourceCount(0);
    // eslint-disable-next-line
  }, [scResources]);

  const factorBoxTooltip = (
    <>
      The Factor value is the supposed difficulty of making the product with the given types of inputs. Each Process has
      a different Factor Value based on those particular inputs. Generally the process at the bottom of the options
      require a higher value because the inputs are not as productive.
    </>
  );

  return (
    <Row style={{ margin: '8px 0', padding: 8, border: '1px solid #EEE' }}>
      <Tooltip title={factorBoxTooltip}>
        <FactorScoreCard $isAllowed={isFactorAllowed}>
          {data.ProductRequired}
          <br />
          Factor
        </FactorScoreCard>
      </Tooltip>
      <div className="flex-1 d-flex flex-column justify-center">
        <div className="d-flex">
          <div className="d-flex align-center flex-1">
            <div className="resource-link" onClick={() => setSelectedResourceId(primaryResource.resourceId)}>
              <img width={24} src={primaryResourceIconUrl} alt={primaryResource.resourceNameLocalized} />
              <div style={{ marginLeft: 4 }}>{primaryResource.resourceNameLocalized}</div>
            </div>
            <div className="flex-1 text-right mr-8">
              {Math.max(primarySCResource.currentSimulationYearRemainingUses - primaryResourceCount * repeatCount, 0)}{' '}
              Uses Remaining This Year
            </div>
            <InputNumber2
              size="small"
              min={0}
              max={primarySCResource.currentSimulationYearRemainingUses}
              value={primaryResourceCount}
              onChange={e => setPrimaryResourceCount(e)}
              disabled={!isFactorAllowed}
            />
          </div>
        </div>
        {!!secondaryResource && (
          <div className="d-flex mt-8">
            <div className="d-flex align-center flex-1">
              <div className="resource-link" onClick={() => setSelectedResourceId(secondaryResource.resourceId)}>
                <img width={24} src={secondaryResourceIconUrl} alt={secondaryResource.resourceNameLocalized} />
                <div style={{ marginLeft: 4 }}>{secondaryResource.resourceNameLocalized}</div>
              </div>
              <div className="flex-1 text-right mr-8">
                {Math.max(
                  secondarySCResource.currentSimulationYearRemainingUses - secondaryResourceCount * repeatCount,
                  0,
                )}{' '}
                Uses Remaining This Year
              </div>
              <InputNumber2
                size="small"
                min={0}
                max={secondarySCResource.currentSimulationYearRemainingUses}
                value={secondaryResourceCount}
                onChange={e => setSecondaryResourceCount(e)}
                disabled={!isFactorAllowed}
              />
            </div>
          </div>
        )}
      </div>
      <InputScoreCard $isAllowed={isInputAllowed}>
        {primaryResourceCount * (secondaryResource ? secondaryResourceCount : 1)}
        <br />
        Inputs
      </InputScoreCard>
    </Row>
  );
};
