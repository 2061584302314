import { Avatar, Dropdown, Menu } from 'antd';
import DataService from '../../services/DataService';
import SessionService from '../../services/SessionService';
import { getDropdownMenuItems } from './data';
import { Logo, StyledHeader, StyledHeaderRight } from './styles';

export const AppHeader = ({ initial, headerMenuItems }) => {
  const menuItems = getDropdownMenuItems();

  const handleClickMenuItem = ({ key }) => {
    switch (key) {
      case 'logout':
        DataService.clear();
        SessionService.logout();
        break;
      default:
    }
  };

  return (
    <StyledHeader>
      <Logo>
        Simpolicon<span style={{ fontSize: 20 }}>©2025</span>
      </Logo>
      <StyledHeaderRight>
        <Menu theme="dark" mode="horizontal" items={headerMenuItems} disabledOverflow selectedKeys={['help']} />
        <Dropdown
          placement="bottomRight"
          trigger={['click']}
          menu={{
            items: menuItems,
            onClick: handleClickMenuItem,
          }}
        >
          <a href="/" onClick={e => e.preventDefault()}>
            <Avatar size="large" style={{ marginLeft: 16, backgroundColor: '#1890FF' }}>
              {initial}
            </Avatar>
          </a>
        </Dropdown>
      </StyledHeaderRight>
    </StyledHeader>
  );
};
