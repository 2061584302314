import { Card, Col, Modal, Row } from 'antd';

export const CreditsModal = ({ open, onClose }) => (
  <Modal width="80%" title="Credits" footer={null} open={open} onCancel={onClose}>
    <Card className="mt-16 text-center" size="small">
      <span style={{ fontSize: 16 }}>Simpolicon©</span>
      <br />
      was created by
      <br />
      <span style={{ fontSize: 16, fontWeight: 700 }}>Denney Daetz</span>
      <br />
      with support, encouragement, and early-on classroom implementation by
      <br />
      Charles Newport and Leo McKenna.
    </Card>
    <Card
      className="mt-16"
      size="small"
      title="Credits for the establishment of the Akwaaba Foundation and the creation of the web version, Simpolicon 1.0©:"
    >
      <Row>
        <Col span={6}>
          Ronald Adolfon, C.P.A.
          <br />
          Alex Chiocchi
          <br />
          Tyler Dikman
          <br />
          Susan Katz
          <br />
          William W. Stubbs
        </Col>
        <Col span={6}>
          Andy Ashkar
          <br />
          William Chiocchi
          <br />
          Lisa Furlo
          <br />
          Joan Pinder
          <br />
          Julie K. Young, Ph.D
        </Col>
        <Col span={6}>
          Cyhthia Chang
          <br />
          Denney Daetz
          <br />
          Lynn Hasting
          <br />
          Mary Roll
        </Col>
        <Col span={6}>
          Adrianna Chiocchi
          <br />
          Laura Daetz
          <br />
          Cameron Hempstead
          <br />
          Tracy & Jane Scott
        </Col>
      </Row>
    </Card>
    <Card className="mt-16" size="small" title="Financial Supporters">
      <Row>
        <Col span={6}>
          ABC7 / KGO-TV, San Francisco
          <br />
          Lise Aubry
          <br />
          Jennifer E. Baumann
          <br />
          Dr. & Mrs. Kwabena Blankson
          <br />
          Mr. & Mrs. Joseph Calpin
          <br />
          Adrianna Chiocchi
          <br />
          Corbett Charitable Fund
          <br />
          Laura & Denney Daetz
          <br />
          Virginia S. Daetz
          <br />
          Steve DiPietro
          <br />
          Trudy Esrey
          <br />
          Gerletti-Weiss
          <br />
          Sue Heinz, (White Design)
          <br />
          Gwendolyn Juha
          <br />
          David King
          <br />
          Julia Lee
          <br />
          Mr. & Mrs. Leo McKenna
          <br />
          Mr. & Mrs. Charles Newport
          <br />
          Joan Pinder
          <br />
          Mr. & Mrs. Dennis Rawson
          <br />
          Mary Roll
          <br />
          Mr. & Mrs. James Simpson
          <br />
          Jana Turner
          <br />
          Kenneth Zak
        </Col>
        <Col span={6}>
          Mr. & Mrs. Joe Achor
          <br />
          Dr. & Mrs. Donald Avoy
          <br />
          Mr. & Mrs. Richard Bavetta
          <br />
          Mr. & Mrs. William Boles
          <br />
          Peter S. Carpenter
          <br />
          Alex Chiocchi
          <br />
          Stephanie Cottrell
          <br />
          Mr. & Mrs. Daniel Daetz
          <br />
          Reshma R. Dalia
          <br />
          Christine Duffy-Watson
          <br />
          Mary Lea Filbert
          <br />
          Wayne Greene & Marcia Fox
          <br />
          Dr. Jeffrey Hicks & Jani Hoberg
          <br />
          Daniel S. Kelly
          <br />
          Dr. & Mrs. Earl Koberlein
          <br />
          Mr. & Mrs. Stephen Martin
          <br />
          Mr. & Mrs. Peter Michon
          <br />
          Mr. & Mrs. Randy Nichols
          <br />
          Mr. & Mrs. Terry Poore
          <br />
          Mr. & Mrs. David Regnier
          <br />
          Dr. & Mrs. Randy Roth
          <br />
          Mr. & Mrs. Kevin Stockton
          <br />
          Cheryl Viernow
          <br />
          Mr. & Mrs. Barry Zimmerman
        </Col>
        <Col span={6}>
          Mr. & Mrs. Dwight Armstrong
          <br />
          Clair U. Bagley
          <br />
          Patricia Blair
          <br />
          Scott Borrison
          <br />
          Mr. & Mrs. Steve Casler
          <br />
          William Chiocchi
          <br />
          Mr. & Mrs. Edwin Cross
          <br />
          Mr. & Mrs. David Daetz
          <br />
          Trung Diep
          <br />
          David Faland
          <br />
          Mary D. Fortin
          <br />
          Mr. & Mrs. Clifford Gutterman
          <br />
          Mr. & Mrs. Kevin Holden
          <br />
          Mr. & Mrs. Steven Kimberlin
          <br />
          Luella B. Kurani
          <br />
          Roger Mason
          <br />
          David Mitchell & Joan Martell
          <br />
          Drs. Michael & Rona Pehrson
          <br />
          Sarah Poveromo
          <br />
          Dr. and Mrs. Hugh Roberts
          <br />
          Jane Scott
          <br />
          Nancy Stump
          <br />
          Kim Worsencroft
        </Col>
        <Col span={6}>
          Dr. & Mrs. Michael Ashcraft
          <br />
          Kathleen Sagesse Barry
          <br />
          Mr. & Mrs. Marshall Blank
          <br />
          Mr. & Mrs. C. Preston Butcher
          <br />
          Mr. & Mrs. David Chapman
          <br />
          Cooltronics, Inc.
          <br />
          Dr. & Mrs. Douglas Daetz
          <br />
          Mr. & Mrs. Steven Daetz
          <br />
          Mary D. DiGeronimo
          <br />
          Mr. & Mrs. Jon Easterbrook
          <br />
          Alan B. Franklin
          <br />
          Elizabeth Hallin
          <br />
          Mr. & Mrs. Steve Holden
          <br />
          Daniel King
          <br />
          Lynda Lane
          <br />
          Dr. & Mrs. Dennis Matthews
          <br />
          Jeffrey Steven Moench
          <br />
          Mr. & Mrs. Thomas Pehrson
          <br />
          Cathy Quatsoe
          <br />
          Lillian Rogers
          <br />
          Johnathon Seymour & Ama Daetz
          <br />
          Mr. & Mrs. Randy Talley
          <br />
          Mariko Yamashir
        </Col>
      </Row>
    </Card>
    <Card className="mt-16" size="small" title="Designers & Programmers">
      <Row>
        <Col span={6}>
          Daniel L. Hoffman
          <br />
          Linda Gates
          <br />
          Kara Walker
        </Col>
        <Col span={6}>
          Brian 'Musti' Welkin
          <br />
          David 'Zac' Belnap
          <br />
          Aaron Walker
        </Col>
        <Col span={6}>
          Seungoh Paek
          <br />
          Christopher Ford
        </Col>
        <Col span={6}>
          Kuo-Hsun Hung
          <br />
          Cathy Quatsoe
        </Col>
      </Row>
    </Card>
    <Card className="mt-16" size="small" title="Webmaster">
      David King
    </Card>
    <Card className="mt-16" size="small" title="Icon Creators">
      <Row>
        <Col span={6}>
          U.N. Office for the Coordination of Humanitarian Affairs (OCHA)
          <br />
          Rocklin High School (CA) Graphic Communications Class
          <br />
          Instructor: Amy Tackett
          <br />
          Sam Harned
          <br />
          Kristen Meadows
        </Col>
        <Col span={6}>
          The Noun Project
          <br />
          Angelica Smith Fabela
          <br />
          Chandler Giaccani
          <br />
          Austin March
          <br />
          Valerie Moreno
        </Col>
        <Col span={6}>
          Delanie Chrisman
          <br />
          Andrew Fink
          <br />
          Tommy Goette
          <br />
          Cameron Marshall
          <br />
          Madison (Arin) Muhic
        </Col>
        <Col span={6}>
          Nathan Dolan
          <br />
          Brennen Flentge
          <br />
          Conor Gohl
          <br />
          Trevor McGoldrick
          <br />
          Alex Rakeman
        </Col>
      </Row>
    </Card>
    <Card className="mt-16 text-center" size="small" title="Educational Researchers">
      Helen Au, Ph.D., Curriculum Research & Development Group, University of Hawail at Manoa, Honolulu, HI
      <br />
      Daniel L. Hoffman, Ed.D., Department of Math, Science, & Technology, Teachers College Columbia University, New
      York, NY
      <br />
      Seungoh Paek, Ed.D., Department of Learning Design & Technology, University of Hawaii at Manoa, Honolulu, HI
    </Card>
    <Card className="mt-16" size="small" title="Translation Providers (Pro Bono)">
      <Row>
        <Col span={6}>
          Vanessa Arnaud (French)
          <br />
          Charise Carver (Spanish)
          <br />
          Gisela Daetz (German)
          <br />
          Michael Milligan (Spanish)
          <br />
          Adrian Romero-Ortiz (Spanish)
          <br />
          John Swanton (Spanish)
        </Col>
        <Col span={6}>
          Bernadette Berryessa (Spanish)
          <br />
          Sara Casler (Spanish) Chair, Spanish Language Dept, Sierra College
          <br />
          Erik Haight (Spanish)
          <br />
          Jerad Morgan (Spanish)
          <br />
          Sierra College, World Languages Dept.
          <br />
          Rebecca Thomas (Spanish)
        </Col>
        <Col span={6}>
          Max Blinoff (Spanish)
          <br />
          Katelyn Clark (Spanish)
          <br />
          Brian Harr (Spanish)
          <br />
          Travis Powell (Spanish)
          <br />
          Sierra College, Spanish Language Dept.
          <br />
          Joanna Troth (Spanish)
        </Col>
        <Col span={6}>
          Jessica Burgess (Spanish)
          <br />
          Wendy Crawford (Spanish) Instructor, Sierra College
          <br />
          Tara Kellerman (Spanish)
          <br />
          Aubrey Railsback (Spanish)
          <br />
          Gavin Smith (Spanish)
          <br />
          Bailey Voss (Spanish)
        </Col>
      </Row>
    </Card>
    <Card className="mt-16" size="small" title="Definitions and Data Sources:">
      Agricultural and Resource Fronomics UIC Davis. Rritish Geological Survev. CIA World Factbook. Food and Acriculture
      Organization of the United Nations (FAOSTAT). Google. Illinois State Water Survev. Index Mund Mans of the World.
      Orecon State Universit. NationMaster.com. Rogers International Commodities Index. UNESCO. United Nations. United
      States Department ot Aariculture. United States Department ot the Interior. United States Geological Survey,
      Various websites dealing with cocoa, cottee, concrete, cotton, fertilizer. niber, Dader. rubber etc. production,
      too numerous to list. Wikidedia, wIseGeek, World Bank, World Health Organization
    </Card>
    <Card className="mt-16 text-center" size="small" title="Software Licenses:">
      Apache Flex 14.5 © 2016 The Apache Software Foundation. See http://www.apache.org/.
      <br />
      Map Data © 2016 Open Street Map and Contributors. See http://www.openstreetmap.org/copyright.
      <br />
      amCharts Flex 1.8.3.4 © 2016 amCharts. See https://www.amcharts.com/terms-conditions/.
    </Card>
  </Modal>
);
