import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const GDPTooltip = ({ title = 'GDP', showIcon = false }) => {
  const tooltip = (
    <>
      GDP(Gross Domestic Product) is the value of all final goods produced or services performed{' '}
      <b>during a particular year</b>. 'Value' is Simpolicon's estimated relative difficulty of production. 'Final' is
      the net gain of a particlar product or service during that particular year.
    </>
  );

  return (
    <Tooltip title={tooltip}>
      {title}
      {showIcon && (
        <>
          &nbsp;
          <QuestionCircleOutlined />
        </>
      )}
    </Tooltip>
  );
};
