import { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import Swal from 'sweetalert2';
import { useSimulationContext } from '../../contexts/SimulationContext';
import { SIMULATION_COUNTRY_STATES } from '../../data';
import { SIDER_MENU_ITEMS } from './data';
import { StyledSider } from './styles';

export const AppMenu = () => {
  const location = useLocation();
  const params = useParams();
  const { allowedRoutes, simulationCountry } = useSimulationContext();
  const selectedKey = location.pathname.split('/').pop();

  const siderMenuItems = useMemo(
    () =>
      SIDER_MENU_ITEMS.map((item, index) => {
        const newItem = { ...item };
        newItem.path = newItem.path.replace(':simulationCountryId', params.simulationCountryId);
        newItem.label = <Link to={newItem.path}>{newItem.label}</Link>;

        if (!allowedRoutes?.includes(newItem.key)) {
          newItem.disabled = true;
        }

        if (index === SIDER_MENU_ITEMS.length - 1) {
          if (
            [SIMULATION_COUNTRY_STATES.END_YEAR, SIMULATION_COUNTRY_STATES.END_SIMULATION].includes(
              simulationCountry?.simulationCountryState,
            )
          ) {
            newItem.label = <Link to={newItem.path}>End Year</Link>;
            newItem.icon = <img width={32} src="/images/layouts/simulation/end.png" alt="" />;

            if (location.pathname.split('/').pop() !== 'feed-the-people') {
              // Show Reminder
              if (!document.querySelector('.swal2-container')) {
                Swal.fire({
                  icon: 'info',
                  text: 'Be sure to press the End Year button at bottom left to actually end the year and see your population changes and other production adjustments.',
                });
              }
            }
          }
        }

        return newItem;
      }),
    [allowedRoutes, simulationCountry, params.simulationCountryId],
  );

  return (
    <StyledSider width={240}>
      <Menu style={{ height: '100%' }} mode="inline" items={siderMenuItems} selectedKeys={[selectedKey]} />
    </StyledSider>
  );
};
