import React, { useRef } from 'react';
import { InputNumber } from 'antd';

const InputNumber2 = ({ size, min, max, value, onChange, ...props }) => {
  const ref = useRef();

  const handleKeyUp = e => {
    let newValue = ref.current.value.replace(/\D/g, '') || '0';
    let rangedNewValue = +newValue;
    if (rangedNewValue < min) {
      rangedNewValue = min;
    } else if (rangedNewValue > max) {
      rangedNewValue = max;
    }

    if (/\D/g.test(ref.current.value)) {
      e.preventDefault();
      e.target.value = value;
      e.target.blur();
      setTimeout(() => {
        e.target.select();
      }, 100);
      return;
    }
    if (rangedNewValue !== +newValue) {
      e.preventDefault();
      handleChange(rangedNewValue);
      e.target.value = rangedNewValue;
      e.target.blur();
      setTimeout(() => {
        e.target.select();
      }, 100);
      return;
    }
  };

  const handleChange = v => {
    let newValue = v;
    if (newValue < min) {
      newValue = min;
      setTimeout(() => {
        ref.current.select();
      }, 110);
    } else if (newValue > max) {
      newValue = max;
    }
    onChange(Math.floor(newValue));
  };

  return (
    <InputNumber
      ref={ref}
      size={size}
      min={min}
      max={max}
      value={value}
      onKeyUp={handleKeyUp}
      onChange={handleChange}
      {...props}
    />
  );
};

export default InputNumber2;
